<template>
  <div>
    <!-- <h3 class="text-center font-weight-normal mb-5">Selección de Plan</h3> -->
    <form class="text-center">
      <div class="row mt-4">
        <div class="col-md-6">
          <h4 class="font-weight-light">Tipo de seguro</h4>
          <h4 class="font-weight-bold text-primary">
            {{ query.selectedPackage }}
          </h4>
        </div>
        <div class="col-md-6">
          <h4 class="font-weight-light">Código postal</h4>
          <h4 class="font-weight-bold text-primary">
            {{ query.postalCode }}
          </h4>
        </div>
      </div>

      <hr />
      <div class="text-center row">
        <div class="container text-justify">

          
          <PlansV4 ref="planV4" :zipCode="query.postalCode" v-on="{go:() => nextStep()}"  :package="query.selectedPackage" :key="componentKey" />

            <!-- <div class="text-center">
            <a
              href="javascript:void"
              @click="nextStep()"
              class="btn btn-sm btn-primary"
            >
              <span
                v-if="disabled === true"
                class="mr-3 spinner-grow spinner-grow-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>

              Continuar  <arrow-right-icon size="1.5x" class="custom-class"></arrow-right-icon>
            </a>

          </div>  -->


        </div>

   
       
         
    
      </div>
    </form>
  </div>
</template>

<script>
import PlansV4 from "@/components/plans/PlansV4";
import { ArrowRightIcon } from 'vue-feather-icons'


export default {
  props: ['zones'],
  components: {
    PlansV4,
    ArrowRightIcon
  },

  computed: {
    query() {
      return this.$route.query;
    },
  },

  methods:{
    nextStep() {
     this.$emit("next");
    },
  }
};
</script>